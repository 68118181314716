export const NavItems = [
  "Photography",
  "Art",
  "Music",
  "Fashion",
  "Stories",
  "About",
  // "Shop",
  // "Cart",
]
